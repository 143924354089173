/* Element Layer */

* {
	font-family: 'Hiragino Kaku Gothic Pro';
}

html {
	font-size: 62.5%;
}

body {
	font-size: 1.6rem;
	font-weight: normal;
	line-height: 1.6;
	color: $black-dark-grey;
	-webkit-font-smoothing: antialiased;
	word-wrap: break-word;
	overflow-wrap: break-word;
}
